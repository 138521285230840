import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper";
import "swiper/css";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import { Baseurl3 } from "../../../config/BaseUrl";
import axios from "axios";


import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const DryFruifsSubcat = () => {
  const [subCategory, setSubCategory] = useState([]);
  const [category, setCategory] = useState("");

  useEffect(() => {
    const categoryfetchData = async () => {
      const url = `${Baseurl3}/api/v1/category/categorybyid/6475cc66f469392b6db05898`;
      const category = await axios.get(url);

      try {
        if (category.data.success === true) {
          const cat = category.data.category;

          setCategory(cat);
        }
      } catch (error) {}
    };
    categoryfetchData();

    const fetchData = async () => {
      const url = `${Baseurl3}/api/v1/subcategory/subcategoryid/6475cc66f469392b6db05898`;
      const fetchSubcat = await axios.get(url);

      try {
        if (fetchSubcat.data.success === true) {
          const subcat = fetchSubcat.data.subcategory.filter(
            (data) => data.status === true
          );

          setSubCategory(subcat);
        }
      } catch (error) {}
    };
    fetchData();
  }, []);

  return (
    <>
      {subCategory.length > 0 ? (
        <section className="category-section-3 masalabanner">
          <div className="container-fluid-lg">
            <div className="title">
              <h2 style={{ textTransform: "capitalize" }}>
                {category.name} 
              </h2>
            </div>
            <div className="row">
              <div className="col-12 d-flex flex-wrap">
                {subCategory &&
                  subCategory.map((subcat, index) => (
                    <div
                      className="category-slider-1 flex-gap arrow-slider wow fadeInUp  col-xxl-2 col-lg-2 col-md-3 col-6"
                      key={index}
                    >
                      <div className="  category-box-list ">
                        <a className="category-name">
                          <h4>{subcat.name}</h4>
                        </a>
                        <div className="category-box-view">
                          <Link to={`/${category.slugUrl}/${subcat.slugUrl}`}>
                            <a>
                              <img
                                src={subcat.desktopImage}
                               
                                className="img-fluid blur-up lazyload"
                                alt="img"
                                height={120}
                                style={{
                                  maxHeight: "120px",
                                  minHeight: "100px",
                                }}
                              />
                            </a>
                          </Link>
                          <Link to={`/${category.slugUrl}/${subcat.slugUrl}`}>
                            <button className="btn shop-button">
                              <span>Shop Now</span>
                              <i className="fas fa-angle-right" />
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
      

<section className="home-section-2 home-section-bg pt-0 overflow-hidden drybanner">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <Swiper 
                className="homeSlider mt-2"
              >
                {
                  subCategory.map((subcat, index) => (
                    <SwiperSlide key={index}>
                      <div className="slider-animate">
                        <div className="home-contain rounded-0 p-0 m-2">
                          <Link to={`/${category.slugUrl}/${subcat.slugUrl}`}>
                            <img
                              src="/assets/images/theme/Dry Fruits, Oils & Masalas.png"
                             
                              className="img-fluid bg-img blur-up lazyload"
                              alt="img"
                              style={{ cursor: "pointer",height:"150px"}}
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          
          </div>
        </div>
      </section>

    </>
  );
};

export default DryFruifsSubcat;
