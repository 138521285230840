import React, { useEffect, useState } from "react";
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
// import Loader from "react-loader-spinner";
import BeatLoader from "react-spinners/BeatLoader";
import { FaStar } from "react-icons/fa";
import { FaStarHalfAlt } from "react-icons/fa";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Baseurl, Baseurl3 } from "../config/BaseUrl";
import axios from "axios";
import {
  addtoCart,
  decrementCart,
} from "../redux/cart/CartSlice";
import Description from "./Home/Hotproductdetails/Description";
import HotProductSlider from "./Home/Hotproductdetails/HotProductSlider";

const ProductDetails = () => {
  const { ProductTotal, CartItems, Cartloading, ProductLoading } = useSelector(
    (state) => state.Cart
  );

  const params = useParams();
  const dispatch = useDispatch();

  const [showButton, setShowButton] = useState(false);
  const [productifo, setProductinfo] = useState([]);
  const [subCatproduct, setSubProduct] = useState([]);
  const [product, setProduct] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      const productslugurl = params.producturl;

      const fetchData = async () => {
        const url = `${Baseurl3}/api/v1/grocery/Grocerybyslugurl/${productslugurl}`;
        const currentProduct = await axios.get(url);

        try {
          if (currentProduct.data.success === true) {
            setLoading(false);
            console.log("productifo", productifo);
            let productInfo = currentProduct.data.grocery;

            let fetchQntyproducts = productInfo.map((product) => {
              let cartPackquentity = [];
              let packqnty = {};

              for (let index = 0; index < product.PackSizes.length; index++) {
                const element = product.PackSizes[index];
                packqnty = {
                  packId: element._id,
                  CartQuantity: 0,
                  Pri: element.Pri,
                };
                cartPackquentity = [...cartPackquentity, packqnty];
                cartPackquentity = [...cartPackquentity];
              }
              product = { ...product, cartPackquentity };
              return product;
            });

            CartItems.forEach((cartitem) => {
              let itemIndex = -1;
              itemIndex = fetchQntyproducts.findIndex(
                (product) => String(product._id) === String(cartitem.ProductId)
              );

              if (itemIndex >= 0) {
                let packIndex = -1;

                packIndex = fetchQntyproducts[
                  itemIndex
                ].cartPackquentity.findIndex(
                  (pack) => String(pack.packId) === String(cartitem.packsizeId)
                );

                fetchQntyproducts[itemIndex].cartPackquentity[
                  packIndex
                ].CartQuantity = 1;
                if (packIndex >= 0) {
                  fetchQntyproducts[itemIndex].cartPackquentity[
                    packIndex
                  ].CartQuantity = 1;
                }
              }
            });

            setProductinfo(fetchQntyproducts);
          }
        } catch (error) {}
      };

      fetchData();

      if (!ProductLoading) {
        const currentProd = ProductTotal.find(
          (product) => product.Url === productslugurl
        );

        if (currentProd !== "") {
          try {
            const currentProduct = ProductTotal.filter(
              (product) => product.Url === productslugurl
            );

            const curSubCatProd = ProductTotal.filter(
              (product) => product.SubCatId === currentProd.SubCatId
            );

            const singlePro = curSubCatProd.filter(
              (product) => product.Single === true
            );
            const offerPro = singlePro.filter(
              (product) => product.Trending === true
            );
            setSubProduct(offerPro);
          } catch (error) {}
        }
      }
    } catch (error) {}
  }, [params.producturl, ProductLoading]);

  const addtocartPress = async (e, product, itemIndex, packIndex) => {
    try {
      let products = [...productifo];
      products[itemIndex].cartPackquentity[packIndex].CartQuantity =
        products[itemIndex].cartPackquentity[packIndex].CartQuantity + 1;
      setProductinfo([...products]);
    } catch (error) {}

    const formData = {
      ProductId: product._id,
      ProductName: product.ItemName,
      CatName: product.Category,
      CatId: product.CatId,
      SubCat: product.SubCat,
      SubCatId: product.SubCatId,
      Brand: product.Brand,
      ItemName: product.ItemName,
      PackSize: product.PackSizes[packIndex].PackSize,
      Description: product.Description,
      ImgUrl: product.PackSizes[packIndex].ImgUrlMbl,
      Price: product.PackSizes[packIndex].SellingPrice,
      Qty: 1,
      TotalAmount: product.PackSizes[packIndex].SellingPrice * 1,
      Mrp: product.PackSizes[packIndex].Mrp,
      TotalPrice: product.PackSizes[packIndex].SellingPrice * 1,
      TotalMrp: product.PackSizes[packIndex].Mrp * 1,
      Discount:
        product.PackSizes[packIndex].Mrp * 1 -
        product.PackSizes[packIndex].SellingPrice * 1,
      Cashback: product.Cashback,
      SellingPrice: product.PackSizes[packIndex].SellingPrice,
      GstSellPrc: product.PackSizes[packIndex].GstSellPrc,
      CostPrc: product.PackSizes[packIndex].CostPrc,
      GstCost: product.PackSizes[packIndex].GstCost,
      packsizeId: product.PackSizes[packIndex]._id,
      maximumQuantity: product.PackSizes[packIndex].maximumQuantity,
    };
    dispatch(addtoCart(formData));
  };

  const decrementtocartPress = async (e, product, itemIndex, packIndex) => {
    try {
      let products = [...productifo];
      products[itemIndex].cartPackquentity[packIndex].CartQuantity =
        products[itemIndex].cartPackquentity[packIndex].CartQuantity - 1;
      setProductinfo([...products]);
    } catch (error) {}

    const formData = {
      ProductId: product._id,
      ProductName: product.ItemName,
      CatName: product.Category,
      CatId: product.CatId,
      SubCat: product.SubCat,
      SubCatId: product.SubCatId,
      Brand: product.Brand,
      ItemName: product.ItemName,
      PackSize: product.PackSizes[packIndex].PackSize,
      Description: product.Description,
      ImgUrl: product.PackSizes[packIndex].ImgUrlMbl,
      Price: product.PackSizes[packIndex].SellingPrice,
      Qty: 1,
      TotalAmount: product.PackSizes[packIndex].SellingPrice * 1,
      Mrp: product.PackSizes[packIndex].Mrp,
      TotalPrice: product.PackSizes[packIndex].SellingPrice * 1,
      TotalMrp: product.PackSizes[packIndex].Mrp * 1,
      Discount:
        product.PackSizes[packIndex].Mrp * 1 -
        product.PackSizes[packIndex].SellingPrice * 1,
      Cashback: product.Cashback,
      SellingPrice: product.PackSizes[packIndex].SellingPrice,
      GstSellPrc: product.PackSizes[packIndex].GstSellPrc,
      CostPrc: product.PackSizes[packIndex].CostPrc,
      GstCost: product.PackSizes[packIndex].GstCost,
      packsizeId: product.PackSizes[packIndex]._id,
      maximumQuantity: product.PackSizes[packIndex].maximumQuantity,
    };
    dispatch(decrementCart(formData));
  };

  const changePrimery = (productId, packId, proindex) => {
    let product = [...productifo];
    product[proindex].PackSizes = product[proindex].PackSizes.map((pack) => {
      if (pack._id === packId) {
        pack.Pri = true;
      } else {
        pack.Pri = false;
      }

      return pack;
    });

    setProductinfo([...product]);
  };

  return (
    <>
      {/* <section className="breadscrumb-section pt-0">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12">
              <div className="breadscrumb-contain">
                <h2>{product.ItemName}</h2>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a>
                        <i className="fa-solid fa-house" />
                      </a>
                    </li>
                    <li className="breadcrumb-item active">Product Info</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {loading ? (
        <>
          <div className="d-flex justify-content-center loadingMain">
            <h4 style={{ fontWeight: "bold", color: "#36d7b7" }}>
              Product is being fetched
            </h4>
            <BeatLoader
              color={"#36d7b7"}
              loading={loading}
              size={10}
              className="mt-2 mx-2"
            />
          </div>
        </>
      ) : (
        <>
          <section className="product-section" style={{marginTop:"10px"}}>
            <div className="container-fluid-lg">
              {productifo.map((data, proindex) => (
                <>
                  <div key={proindex}>
                    {data.PackSizes.map((pack, imgpackIndex) =>
                      pack.Pri === true ? (
                        <>
                          <div className="row" key={imgpackIndex}>
                            <div className="col-xxl-9 col-xl-8 col-lg-7 wow fadeInUp">
                              <div className="row g-4 m-4" >
                                <div className="col-xl-6 wow fadeInUp">
                                  <div className="product-left-box">
                                    <div className="row g-2">
                                      <div className="col-xxl-10 col-lg-12 col-md-12">
                                        <div className="product-main-2 no-arrow">
                                          <div>
                                            <div
                                              className="slider-image"
                                              style={{
                                                display: "flex",
                                                display: "-webkit-flex",
                                                justifyContent: "center",
                                                width: "100%",
                                              }}
                                            >
                                              {pack.hasOwnProperty(
                                                "ImgUrlDesk"
                                              ) ? (
                                                <>
                                                  <img
                                                    src={pack.ImgUrlDesk}
                                                    id="img-1"
                                                    data-zoom-image={
                                                      pack.ImgUrlDesk
                                                    }
                                                    className="img-fluid image_zoom_cls-0 blur-up lazyload"
                                                    key={imgpackIndex}
                                                    alt="img"
                                                    width="80%"
                                                  />
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="col-xl-6 wow fadeInUp"
                                  data-wow-delay="0.1s"
                                >
                                  <div className="right-box-contain">
                                    <h6 className="offer-top">
                                      {pack.Discount}% Off
                                    </h6>
                                    <h2 className="name">{data.ItemName}</h2>

                                    <div className="price-rating">
                                      <h3 className="theme-color price">
                                        ₹ {pack.SellingPrice}
                                        {pack.Mrp === pack.SellingPrice ? (
                                          <> </>
                                        ) : (
                                          <>
                                            <del>
                                              <del
                                                className="text-content"
                                                style={{
                                                  paddingLeft: "10px",
                                                  paddingRight: "10px",
                                                }}
                                              >
                                                ₹ {pack.Mrp}
                                              </del>{" "}
                                            </del>
                                          </>
                                        )}
                                        <span className="offer theme-color">
                                          ({pack.Discount}% off)
                                        </span>
                                      </h3>
                                      <div className="product-rating custom-rate">
                                        <ul className="rating">
                                          <li>
                                            <FaStar style={{ color: "gold" }} />
                                          </li>
                                          <li>
                                            <FaStar style={{ color: "gold" }} />
                                          </li>
                                          <li>
                                            <FaStar style={{ color: "gold" }} />
                                          </li>
                                          <li>
                                            <FaStar style={{ color: "gold" }} />
                                          </li>
                                          <li>
                                            <FaStarHalfAlt
                                              style={{ color: "gold" }}
                                            />
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="procuct-contain">
                                      <p>
                                        {data.KeyWords}
                                      </p>
                                    </div>

                                    <div className="product-packege">
                                      <div className="product-title">
                                        <h4>Weight</h4>
                                      </div>
                                      <ul className="select-packege">
                                        {data.PackSizes.map(
                                          (pacdata, index) => (
                                            <>
                                              <li
                                                key={index}
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  changePrimery(
                                                    data._id,
                                                    pacdata._id,
                                                    proindex
                                                  )
                                                }
                                              >
                                                <a
                                                  className={
                                                    pacdata.Pri === true
                                                      ? "active"
                                                      : ""
                                                  }
                                                >
                                                  {pacdata.PackSize}
                                                </a>
                                              </li>
                                            </>
                                          )
                                        )}
                                      </ul>
                                    </div>

                                    <div className="note-box product-packege">
                                      {productifo[proindex].cartPackquentity[
                                        imgpackIndex
                                      ].CartQuantity > 0 ? (
                                        <>
                                          {" "}
                                          <div className="cart_qty qty-box product-qty">
                                            <div className="input-group">
                                              <button
                                                type="button"
                                                className="qty-left-minus"
                                                data-type="minus"
                                                data-field
                                                onClick={(e) =>
                                                  decrementtocartPress(
                                                    e,
                                                    data,
                                                    proindex,
                                                    imgpackIndex
                                                  )
                                                }
                                              >
                                                <i
                                                  className="fa fa-minus"
                                                  aria-hidden="true"
                                                />
                                              </button>
                                              <input
                                                className="form-control input-number qty-input"
                                                type="text"
                                                name="quantity"
                                                value={
                                                  productifo[proindex]
                                                    .cartPackquentity[
                                                    imgpackIndex
                                                  ].CartQuantity
                                                }
                                                contentEditable="false"
                                              />

                                              {9 >=
                                              productifo[proindex]
                                                .cartPackquentity[imgpackIndex]
                                                .CartQuantity ? (
                                                <>
                                                  <button
                                                    type="button"
                                                    className="qty-right-plus"
                                                    data-type="plus"
                                                    data-field
                                                    onClick={(e) =>
                                                      addtocartPress(
                                                        e,
                                                        data,
                                                        proindex,
                                                        imgpackIndex
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="fa fa-plus"
                                                      aria-hidden="true"
                                                    />
                                                  </button>
                                                </>
                                              ) : (
                                                <>
                                                  <button
                                                    type="button"
                                                    className="qty-right-plus"
                                                    data-type="plus"
                                                    data-field
                                                  >
                                                    <i
                                                      className="fa fa-plus"
                                                      aria-hidden="true"
                                                    />
                                                  </button>
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            className="btn btn-md bg-dark cart-button text-white w-100"
                                            onClick={(e) =>
                                              addtocartPress(
                                                e,
                                                data,
                                                proindex,
                                                imgpackIndex
                                              )
                                            }
                                          >
                                            Add To Cart
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                 
                                </div>
                                <Description />
                                <div className="col-12"></div>
                              </div>
                            </div>
                            <div className="col-xxl-3 col-xl-4 col-lg-5 d-none d-lg-block wow fadeInUp">
                              <div className="right-sidebar-box">
                                {/* Trending Product */}
                                <div className="pt-25">
                                  <div className="category-menu">
                                    <h3>Trending Products</h3>
                                    <ul className="product-list product-right-sidebar border-0 p-0">
                                      {subCatproduct.map((data, index) => (
                                        <li key={index}>
                                          {data.PackSizes.map((pack) =>
                                            pack.Pri === true ? (
                                              <>
                                                <Link
                                                  to={`/product-info/${data.Url}`}
                                                >
                                                  <div
                                                    className="offer-product"
                                                    key={pack._id}
                                                  >
                                                    <a className="offer-image">
                                                      <img
                                                        src={pack.ImgUrlDesk}
                                                        className="img-fluid blur-up lazyload"
                                                        alt="img"
                                                      />
                                                    </a>
                                                    <div className="offer-detail">
                                                      <div>
                                                        <a>
                                                          <h6 className="name">
                                                            {data.ItemName}
                                                          </h6>
                                                        </a>

                                                        <span>
                                                          {pack.SellingPrice}
                                                        </span>
                                                        {pack.Mrp ===
                                                        pack.SellingPrice ? (
                                                          <> </>
                                                        ) : (
                                                          <>
                                                            {" "}
                                                            <h6 className="price theme-color">
                                                              {pack.Mrp}
                                                            </h6>
                                                          </>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Link>
                                              </>
                                            ) : (
                                              <></>
                                            )
                                          )}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                                {/* Banner Section */}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div key={imgpackIndex}></div>
                        </>
                      )
                    )}
                  </div>
                </>
              ))}
            </div>
          </section>
          {showButton && (
            <div className="sticky-bottom-cart">
              <div className="container-fluid-lg">
                <div className="row">
                  <div className="col-12">
                    <div className="cart-content">
                      <div className="product-image">
                        <img
                          src="../assets/images/product/category/1.jpg"
                          className="img-fluid blur-up lazyload"
                          alt="img"
                        />
                        <div className="content">
                          <h5>Creamy Chocolate Cake</h5>
                          <h6>
                            ₹32.96<del className="text-danger">₹96.00</del>
                            <span>55% off</span>
                          </h6>
                        </div>
                      </div>
                      <div className="selection-section">
                        <div className="form-group mb-0">
                          <select
                            id="input-state"
                            className="form-control form-select"
                          >
                            <option selected disabled>
                              Choose Weight...
                            </option>
                            <option>1/2 KG</option>
                            <option>1 KG</option>
                            <option>1.5 KG</option>
                          </select>
                        </div>
                        <div className="cart_qty qty-box product-qty m-0">
                          <div className="input-group h-100">
                            <button
                              type="button"
                              className="qty-left-minus"
                              data-type="minus"
                              data-field
                            >
                              <i className="fa fa-minus" aria-hidden="true" />
                            </button>
                            <input
                              className="form-control input-number qty-input"
                              type="text"
                              name="quantity"
                              defaultValue={1}
                            />
                            <button
                              type="button"
                              className="qty-right-plus"
                              data-type="plus"
                              data-field
                            >
                              <i className="fa fa-plus" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="add-btn">
                        <a className="btn theme-bg-color text-white wishlist-btn">
                          <i className="fa fa-bookmark" /> Wishlist
                        </a>
                        <a className="btn theme-bg-color text-white">
                          <i className="fas fa-shopping-cart" /> Add To Cart
                        </a>
                        </div>
                        
                      </div>
                      
                    </div>
                    
                  </div>
                  
                </div>
                
              </div>
              
          )}
         
        </>
      )}
      
     <HotProductSlider />
      {/* <div
        style={{
          marginBottom: "20vh",
        }}
      ></div> */}
    </>
  );
};

export default ProductDetails;
