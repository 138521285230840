import React, { useState } from "react";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { AiOutlineCaretRight } from "react-icons/ai";
import { BsHouseDoor, BsPencilFill } from "react-icons/bs";

import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../redux/athentication/Athentication";
import { clearOrder } from "../../redux/order/OrderSlice";
import MyProfile from "./MyProfile";
import MyAddress from "./MyAddress";

const AccoutDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [toggle, setToggle] = useState(true);

  const logout = () => {
    dispatch(authActions.signout());
    dispatch(clearOrder());
    // localStorage.clear();
    navigate("/");
  };

  const handlescrolltop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <section className="breadscrumb-section  pt-0">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12 ">
              <div className="breadscrumb-contain menumainhomebar">
                <h2>User Dashboard</h2>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <BsHouseDoor className="mb-1 mx-1" />
                      </Link>
                    </li>
                    <li className="active" aria-current="page">
                      <span>
                        <AiOutlineCaretRight />
                      </span>
                      User Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="user-dashboard-section section-b-space">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="row">
              <div className="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
                <div className="image-contain">
                  <img
                    src="../assets/images/inner-page/otp.png"
                    className="img-fluid"
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
                <div className="d-flex align-items-center justify-content-center h-100">
                  <div className="log-in-box">
                    <div className="log-in-title">
                      <MyProfile />
                      <MyAddress />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="bg-overlay" />
    </>
  );
};

export default AccoutDetails;
