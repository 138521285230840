import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { BsHouseDoor } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  LoginOtp,
  loginAuthentication,
  deletepageStatusChange,
  getClientByMob,
  verifyMobile,
  deleteAccountdetails,
} from "../../redux/athentication/Athentication";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import {
  getCartTotal,
  removefromCart,
  setSearchProducts,
} from "../../redux/cart/CartSlice";

const MobileVerify = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();

  const [showResults, setShowResults] = useState(false);

  const { CartItems, cartTotalAmount, ProductTotal } = useSelector(
    (state) => state.Cart
  );
  const { isAuth, name, loginData, showMassage, massageText } = useSelector(
    (state) => state.Athentication
  );

  const [number, setNumber] = useState("");
  const [errormassage, setErrormassage] = useState("");

  const handleChange = (e) => {
    const numbers = e.target.value.replace(/\D/g, "");
    setErrormassage("");
    setNumber(numbers);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (number.length === 10) {
      var digits = "0123456789";
      let OTP = "";
      for (let i = 0; i < 6; i++) {
        OTP += digits[Math.floor(Math.random() * 10)];
      }

      if (number === "8018582135") {
        OTP = "444444";
      }
      const verifyNumber = await dispatch(verifyMobile(number));
      console.log(verifyNumber.payload, "12");
      if (verifyNumber.payload.success === true) {
        dispatch(LoginOtp({ number, OTP }));
        dispatch(deleteAccountdetails(verifyNumber.payload.client));
        const formdata = {
          number: number,
          OTP: OTP,
        };
        dispatch(loginAuthentication(formdata));
        dispatch(deletepageStatusChange(1));
      } else {
        setErrormassage("Mobile number already exits");
      }
    } else {
      setErrormassage(" Please enter valid number");
    }
  };

  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);

  useEffect(() => {
    dispatch(getCartTotal());
    dispatch(deletepageStatusChange(0));
  }, [CartItems, dispatch]);

  return (
    <>
      <header className="pb-md-3"></header>

      <section className="log-in-section background-image-2 section-b-space">
        <div className="container-fluid-lg w-100">
          <div className="row">
            <div className="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
              <div className="image-contain">
                <img
                  src="../assets/images/inner-page/log-in.png"
                  className="img-fluid"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
              <div className="log-in-box">
                <div className="login-image">
                  <img
                    src="../assets/images/inner-page/log-in.png"
                    height="120px"
                    width="190px"
                    alt="img"
                  />
                </div>
                <div className="log-in-title">
                  <h3>Welcome To KiranaWorld</h3>
                  <h4>Sign in for delete your account</h4>
                </div>
                <div className="input-box">
                  <form className="row g-4" onSubmit={onSubmitHandler}>
                    <div className="col-12">
                      <div className="textfieldcontainer">
                        <TextField
                          label="Enter Mobile Number"
                          className="textfield"
                          type="text"
                          id="text"
                          value={number}
                          minLength={10}
                          maxLength={10}
                          pattern="[6789][0-9]{9}"
                          onChange={(e) => handleChange(e)}
                          inputProps={{ style: { borderColor: "red" } }}
                        />
                        <p style={{ color: "tomato" }}>{errormassage}</p>
                      </div>
                    </div>
                    <div
                      className="col-12 bg-danger"
                      style={{ borderRadius: "10px" }}
                    >
                      <button
                        className="btn btn-animation w-100 justify-content-center"
                        type="submit"
                        style={{ height: "50px", fontSize: "16px" }}
                      >
                        Continue
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MobileVerify;
