import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

const HTBSubCategorySection = (props) => {
  const { subCategoryTotal } = useSelector((state) => state.SubCategory);
  const { shopbyCategory } = useSelector((state) => state.Category);
  const [SubCategoryList, setSubCategoryList] = useState([]);
  const [curresubctCategory, setCurresubctCategory] = useState("");
  const [currentCategory, setCurrentCategory] = useState("");

  useEffect(() => {
    const currentCatId = props.categoryId;
    console.log(currentCatId, "currentCatId");
    const currentSubCat = subCategoryTotal.filter(
      (subC) => subC.categoryId === currentCatId
    );
    setSubCategoryList(currentSubCat);
  }, [props.categoryId]);

  return (
    <>
      <div className="left-box wow fadeInUp">
        <div className="shop-left-sidebar">
          <div className="back-button">
            <h3>
              <i className="fa-solid fa-arrow-left" /> Back
            </h3>
          </div>
          <div className="accordion custome-accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <span>Sub Categories</span>
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
              >
                <div className="accordion-body">
                  <ul className="category-list custom-padding custom-height">
                    {/* <li>
                      <div className="form-check ps-0 m-0 category-list-box">
                        <label className="form-check-label" htmlFor="fruit">
                          <span
                            className={
                              curresubctCategory === "" ? "nameActive" : "name"
                            }
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              props.onFocus("");
                              setCurresubctCategory("");
                            }}
                          >
                            All
                          </span>
                        </label>
                      </div>
                    </li> */}

                    {SubCategoryList.map((subcat, index) => (
                      <li key={index}>
                        <div className="form-check ps-0 m-0 category-list-box">
                          {/* <input
                            className="checkbox_animated"
                            type="checkbox"
                            id="fruit"
                          /> */}
                          <img
                            height={35}
                            src={subcat.desktopImage}
                            alt="img"
                          />

                          <label className="form-check-label" htmlFor="fruit">
                            <span
                              className={
                                curresubctCategory._id === subcat._id
                                  ? "nameActive"
                                  : "name"
                              }
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                props.onFocus(subcat._id);
                                setCurresubctCategory(subcat);
                              }}
                            >
                              {subcat.name}
                            </span>
                            {/* <span className="number">(15)</span> */}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HTBSubCategorySection;
