import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clearCart } from "../../redux/cart/CartSlice";

const OrderSuccesProducts = () => {
  const { currentOrder } = useSelector((state) => state.Order);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentOrder !== "") {
      dispatch(clearCart());
    }
  }, [currentOrder]);

  return (
    <>
      <div className="col-xxl-9 col-lg-8">
        <div className="cart-table order-table order-table-2">
          <div className="table-responsive">
            <table className="table mb-0">
              <tbody>
                {currentOrder.OrderProducts.map((order, index) => (
                  <tr key={index}>
                    <td className="product-detail">
                      <div className="product border-0">
                        <a
                          href="product.left-sidebar.html"
                          className="product-image"
                        >
                          <img
                            src={order.ImgUrl}
                            className="img-fluid blur-up lazyload"
                            alt
                          />
                        </a>
                        <div className="product-detail">
                          <ul>
                            <li className="name">
                              <a href="product-left-thumbnail.html">
                                {order.ItemName}
                              </a>
                            </li>
                            <li className="text-content">
                              Sold By: {order.Brand}
                            </li>
                            <li className="text-content">
                              Quantity - {order.PackSize}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td className="price">
                      <h4 className="table-title text-content">Price</h4>
                      <h6 className="theme-color">₹ {order.Price}</h6>
                    </td>
                    <td className="quantity">
                      <h4 className="table-title text-content">Qty</h4>
                      <h4 className="text-title"> {order.Qty}</h4>
                    </td>
                    <td className="subtotal">
                      <h4 className="table-title text-content">Total</h4>
                      <h5>₹ {order.TotalPrice}</h5>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderSuccesProducts;
