import React, { useState, useEffect, useRef } from "react";
import { BsHouseDoor } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  authActions,
  deletepageStatusChange,
  getClientByMob,
  loginAuthentication,
  pageStatusChange,
} from "../../redux/athentication/Athentication";
import { getOrderbyClId } from "../../redux/order/OrderSlice";

const OtpVerify = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();

  const { mob, otp, deleteClientDetails } = useSelector(
    (state) => state.Athentication
  );
  const [counter, setCounter] = useState(30);
  const [otpvalue1, setOtpvalue1] = useState("");
  const [otpvalue2, setOtpvalue2] = useState("");
  const [otpvalue3, setOtpvalue3] = useState("");
  const [otpvalue4, setOtpvalue4] = useState("");
  const [otpvalue5, setOtpvalue5] = useState("");
  const [otpvalue6, setOtpvalue6] = useState("");
  const [logerror, setLogerror] = useState("");
  const [logerrorcolor, setLogerrorcolor] = useState("red");

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    document.getElementById("first").focus();
  }, []);

  const otpchange1 = (e) => {
    let value = e.target.value;

    if (value != "" && value >= 0) {
      value = Number(value) % 10;
      setOtpvalue1(value);
      document.getElementById("second").focus();
    } else if (value === "") {
      setOtpvalue1("");
    }
  };
  const otpchange2 = (e) => {
    let value = e.target.value;

    if (value != "" && value >= 0) {
      value = Number(value) % 10;
      setOtpvalue2(value);
      document.getElementById("third").focus();
    } else if (value === "") {
      document.getElementById("first").focus();
      setOtpvalue2("");
    }
  };
  const otpchange3 = (e) => {
    let value = e.target.value;

    if (value != "" && value >= 0) {
      value = Number(value) % 10;
      setOtpvalue3(value);
      document.getElementById("fourth").focus();
    } else if (value === "") {
      document.getElementById("second").focus();
      setOtpvalue3("");
    }
  };
  const otpchange4 = (e) => {
    let value = e.target.value;

    if (value != "" && value >= 0) {
      value = Number(value) % 10;
      setOtpvalue4(value);
      document.getElementById("fifth").focus();
    } else if (value === "") {
      document.getElementById("third").focus();
      setOtpvalue4("");
    }
  };
  const otpchange5 = (e) => {
    let value = e.target.value;

    if (value != "" && value >= 0) {
      value = Number(value) % 10;
      setOtpvalue5(value);
      document.getElementById("sixth").focus();
    } else if (value === "") {
      document.getElementById("fourth").focus();
      setOtpvalue5("");
    }
  };
  const otpchange6 = (e) => {
    let value = e.target.value;

    if (value != "" && value >= 0) {
      value = Number(value) % 10;
      setOtpvalue6(value);
      document.getElementById("btnsubmit").focus();
    } else if (value === "") {
      document.getElementById("fifth").focus();
      setOtpvalue6("");
    }
  };

  const onCero = (e) => {
    e.preventDefault();
    if (e.key === "Delete" || e.key === "Backspace") {
    }
  };

  const verifyClick = async () => {
    let otpvalue =
      String(otpvalue1) +
      otpvalue2 +
      otpvalue3 +
      otpvalue4 +
      otpvalue5 +
      otpvalue6;
    console.log(otp, "otp");
    if (otpvalue.length === 6) {
      setLogerrorcolor("green");
      setLogerror("OTP verifying");
      if (otpvalue === otp) {
        dispatch(deletepageStatusChange(2));
      } else {
        setLogerrorcolor("red");
        setLogerror("please enter correct OTP");
      }
    }
  };

  const resendclick = () => {
    const formdata = {
      number: mob,
      OTP: otp,
    };
    dispatch(loginAuthentication(formdata));
    setCounter(30);
  };

  return (
    <>
      <section className="log-in-section otp-section section-b-space">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
              <div className="image-contain">
                <img
                  src="../assets/images/inner-page/otp.png"
                  className="img-fluid"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
              <div className="d-flex align-items-center justify-content-center h-100">
                <div className="log-in-box">
                  <div className="log-in-title">
                    <h3 className="text-title">
                      Please enter the one time password to verify your account
                    </h3>
                    <h5 className="text-content">
                      A code has been sent to <span>{mob}</span>
                      <span
                        className="mx-3"
                        style={{ color: "#0e947a", cursor: "pointer" }}
                        onClick={() => dispatch(pageStatusChange(0))}
                      >
                        change number
                      </span>
                    </h5>
                  </div>
                  <div
                    id="otp"
                    className="inputs d-flex flex-row justify-content-center"
                  >
                    <input
                      className="text-center form-control rounded"
                      value={otpvalue1}
                      onChange={(e) => otpchange1(e)}
                      type="text"
                      id="first"
                      placeholder={0}
                    />
                    <input
                      className="text-center form-control rounded"
                      value={otpvalue2}
                      onChange={(e) => otpchange2(e)}
                      type="text"
                      id="second"
                      placeholder={0}
                    />
                    <input
                      className="text-center form-control rounded"
                      value={otpvalue3}
                      onChange={(e) => otpchange3(e)}
                      type="text"
                      id="third"
                      placeholder={0}
                    />
                    <input
                      className="text-center form-control rounded"
                      value={otpvalue4}
                      onChange={(e) => otpchange4(e)}
                      type="text"
                      id="fourth"
                      placeholder={0}
                    />
                    <input
                      className="text-center form-control rounded"
                      value={otpvalue5}
                      onChange={(e) => otpchange5(e)}
                      type="text"
                      id="fifth"
                      placeholder={0}
                    />
                    <input
                      className="text-center form-control rounded"
                      value={otpvalue6}
                      onChange={(e) => otpchange6(e)}
                      type="text"
                      id="sixth"
                      placeholder={0}
                    />
                  </div>
                  <div className="send-box pt-4">
                    <h5 style={{ color: logerrorcolor }}>{logerror}</h5>
                  </div>
                  <div className="send-box pt-4">
                    <h5>
                      Didn't get the code?
                      <a className="theme-color fw-bold" onClick={resendclick}>
                        Resend It
                      </a>
                    </h5>
                  </div>
                  <button
                    className="btn btn-animation w-100 mt-3"
                    onClick={verifyClick}
                    id="btnsubmit"
                    type="submit"
                  >
                    Validate
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OtpVerify;
