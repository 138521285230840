// export const Baseurl = "http://localhost:4025";
// export const Baseur2 = "http://localhost:4025";
// export const Baseurl3 = "http://localhost:4025";
// export const Baseurl4 = "http://localhost:4025";
// export const Baseurl5 = "http://localhost:4025";

export const Baseur2 = "https://kiranaworldwebsiteapi.kiranaworld.in";
export const Baseurl = "https://kiranaworldwebsiteapi.kiranaworld.in";
export const Baseurl3 = "https://kiranaworldadminapi.kiranaworld.in";
export const Baseurl4 = 'https://kiranaworaldapi.bayfikar.in';
export const Baseurl5 = 'https://kwwebsitenewapi.kiranaworld.in';
