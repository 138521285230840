import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  suCatSliderTotal: localStorage.getItem("suCatSliderTotal")
    ? JSON.parse(localStorage.getItem("suCatSliderTotal"))
    : [],
  isSuCatSliderAvailable: localStorage.getItem("suCatSliderTotal")
    ? true
    : false,
  VegSupCatSlider: localStorage.getItem("suCatSliderTotal")
    ? JSON.parse(localStorage.getItem("suCatSliderTotal")).filter(
        (ban) => ban.superCategory === "Vegetables & fruits"
      )
    : [],
  nonVegSupCatSlider: localStorage.getItem("suCatSliderTotal")
    ? JSON.parse(localStorage.getItem("suCatSliderTotal")).filter(
        (ban) => ban.superCategory === "Non-veg"
      )
    : [],
  supermartSupCatSlider: localStorage.getItem("suCatSliderTotal")
    ? JSON.parse(localStorage.getItem("suCatSliderTotal")).filter(
        (ban) => ban.superCategory === "Supermart"
      )
    : [],

  isSliderLoading: true,
};

export const getSubCatBanners = createAsyncThunk(
  "SubCategorySlider/getSubCatBanners",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/subcatbslider/all`;
      const resp = await axios.get(url);
      return resp.data.subcategorySliders;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

const subCatSliderAllSlice = createSlice({
  name: "SubCategorySlider",
  initialState,
  reducers: {
    getSubCatSliderset(state, action) {},
  },
  extraReducers: {
    [getSubCatBanners.pending]: (state) => {
      state.isSliderLoading = true;
    },
    [getSubCatBanners.fulfilled]: (state, action) => {
      if (action.payload) {
        state.suCatSliderTotal = action.payload;
        state.VegSupCatSlider = state.suCatSliderTotal.filter(
          (ban) => ban.superCategory === "Vegetables & fruits"
        );
        state.nonVegSupCatSlider = state.suCatSliderTotal.filter(
          (ban) => ban.superCategory === "Non-veg"
        );
        state.supermartSupCatSlider = state.suCatSliderTotal.filter(
          (ban) => ban.superCategory === "Supermart"
        );

        localStorage.setItem(
          "suCatSliderTotal",
          JSON.stringify(state.suCatSliderTotal)
        );

        state.isSliderLoading = false;
        state.isSuCatSliderAvailable = true;
      }
    },
    [getSubCatBanners.rejected]: (state, action) => {
      state.isSliderLoading = true;
    },
  },
});

export const { getSubCatSliderset } = subCatSliderAllSlice.actions;
export default subCatSliderAllSlice.reducer;
