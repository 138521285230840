import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  categoryTotal: localStorage.getItem("categoryTotal")
    ? JSON.parse(localStorage.getItem("categoryTotal"))
    : [],
  isCategoryAvailable: localStorage.getItem("categoryTotal") ? true : false,
  shopbyCategory: localStorage.getItem("categoryTotal")
    ? JSON.parse(localStorage.getItem("categoryTotal")).filter(
        (category) => category.status === true
      )
    : [],
  vegCategory: localStorage.getItem("shopbyCategory")
    ? JSON.parse(localStorage.getItem("shopbyCategory")).filter(
        (category) => category.superCategoryId === "63d8da4ad405398b770b50aa"
      )
    : [],
  nonvegCategory: localStorage.getItem("shopbyCategory")
    ? JSON.parse(localStorage.getItem("shopbyCategory")).filter(
        (category) => category.superCategoryId === "63d8da2bd405398b770b508f"
      )
    : [],
  supermartCategory: localStorage.getItem("shopbyCategory")
    ? JSON.parse(localStorage.getItem("shopbyCategory")).filter(
        (category) => category.superCategoryId === "63d767855658519287238b63"
      )
    : [],

  isCategoryLoading: true,
};

export const getCategory = createAsyncThunk(
  "category/getCategory",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/category/all`;
      const resp = await axios.get(url);
      return resp.data.category;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

const CategorySlice = createSlice({
  name: "Category",
  initialState,
  reducers: {
    setCategories(state, action) {
      state.categoryTotal = action.payload.filter(
        (category) => category.status === true
      );
      state.shopbyCategory = state.categoryTotal;

      localStorage.setItem(
        "categoryTotal",
        JSON.stringify(state.categoryTotal)
      );

      state.isCategoryLoading = false;
      state.isCategoryAvailable = true;
    },
  },
  extraReducers: {
    [getCategory.pending]: (state) => {
      state.isCategoryLoading = true;
    },
    [getCategory.fulfilled]: (state, action) => {
      if (action.payload) {
        state.categoryTotal = action.payload.filter(
          (category) => category.status === true
        );
        state.shopbyCategory = state.categoryTotal.sort((a, b) =>
          a.priority > b.priority ? 1 : -1
        );

        state.vegCategory = state.shopbyCategory.filter(
          (category) => category.superCategoryId === "63d8da4ad405398b770b50aa"
        );
        state.nonvegCategory = state.shopbyCategory.filter(
          (category) => category.superCategoryId === "63d8da2bd405398b770b508f"
        );
        state.supermartCategory = state.shopbyCategory.filter(
          (category) => category.superCategoryId === "63d767855658519287238b63"
        );

        localStorage.setItem(
          "categoryTotal",
          JSON.stringify(state.categoryTotal)
        );
        localStorage.setItem(
          "shopbyCategory",
          JSON.stringify(state.shopbyCategory)
        );

        state.isCategoryLoading = false;
        state.isCategoryAvailable = true;
      }
    },
    [getCategory.rejected]: (state, action) => {
      state.isCategoryLoading = true;
    },
  },
});
export const { setCategories } = CategorySlice.actions;
export default CategorySlice.reducer;
