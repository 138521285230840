import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { addtoCart, decrementCart } from "../../redux/cart/CartSlice";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "@mui/material";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const CatOfferPage = (props) => {
    const { ProductTotal, CartItems, Cartloading, ProductLoading } = useSelector(
        (state) => state.Cart
    );

    const dispatch = useDispatch();
    const [currentSubCatProducts, setCurrentSubCatProducts] = useState([]);
    const [fetchloading, setFetchloading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [curritemIndex, setcurritemIndex] = useState(0);
    const [currentProduct, setCurrentProduct] = useState("");

    const location = useLocation();

    useEffect(() => {
        const { banner } = location.state || {}; // Destructure with a fallback
        if (banner && ProductTotal && ProductTotal.length > 0) {
            // Extract IDs from banner.products array of objects
            const bannerProductIds = banner.products.map((product) => product.ProductId);

            // Filter ProductTotal based on CatId and bannerProductIds
            const filteredProducts = ProductTotal.filter((indPro) =>
                indPro.CatId === banner.categoryId && bannerProductIds.includes(indPro._id)
            );

            const updatePriceProduct = filteredProducts.map((indPro) => ({
                ...indPro,
                PackSizes: indPro.PackSizes.map((indPack) => {
                    const discount = Number(banner.offer) / 100; // Convert percentage to decimal
                    const discountedPrice = indPack.SellingPrice - (indPack.SellingPrice * discount); // Calculate new price

                    return {
                        ...indPack,
                        SellingPrice: discountedPrice, // Update SellingPrice
                    };
                }),
            }));
            
            //   setCurrentCatProducts(filteredProducts)
            let fetchQntyproducts = updatePriceProduct.map((product) => {
                let cartPackquentity = [];
                let packqnty = {};

                for (let index = 0; index < product.PackSizes.length; index++) {
                    const element = product.PackSizes[index];
                    packqnty = {
                        packId: element._id,
                        CartQuantity: 0,
                        Pri: element.Pri,
                    };
                    cartPackquentity = [...cartPackquentity, packqnty];
                    cartPackquentity = [...cartPackquentity];
                }
                product = { ...product, cartPackquentity };
                return product;
            });
            console.log(fetchQntyproducts.length, 'fetchQntyproducts');
            CartItems.forEach((cartitem) => {
                let itemIndex = -1;
                itemIndex = fetchQntyproducts.findIndex(
                    (product) => String(product._id) === String(cartitem.ProductId)
                );

                if (itemIndex >= 0) {
                    let packIndex = -1;

                    packIndex = fetchQntyproducts[itemIndex].cartPackquentity.findIndex(
                        (pack) => String(pack.packId) === String(cartitem.packsizeId)
                    );

                    fetchQntyproducts[itemIndex].cartPackquentity[
                        packIndex
                    ].CartQuantity = 1;
                    if (packIndex >= 0) {
                        fetchQntyproducts[itemIndex].cartPackquentity[
                            packIndex
                        ].CartQuantity = 1;
                    }
                }
            });
            setCurrentSubCatProducts(fetchQntyproducts);
        }
    }, [location.state, ProductTotal]);

    const optionsProductset = (product, itemIndex) => {
        setCurrentProduct(product);
        setcurritemIndex(itemIndex);
        setVisible(!visible);
    };


    // setCurrentSubCatProducts(prod);

    const addtocartPress = async (e, subcatProduct, itemIndex, packIndex) => {
        try {
            let products = [...currentSubCatProducts];
            products[itemIndex].cartPackquentity[packIndex].CartQuantity =
                products[itemIndex].cartPackquentity[packIndex].CartQuantity + 1;
            setCurrentSubCatProducts([...products]);
        } catch (error) { }

        const formData = {
            ProductId: subcatProduct._id,
            ProductName: subcatProduct.ItemName,
            CatName: subcatProduct.Category,
            CatId: subcatProduct.CatId,
            SubCat: subcatProduct.SubCat,
            SubCatId: subcatProduct.SubCatId,
            Brand: subcatProduct.Brand,
            ItemName: subcatProduct.ItemName,
            PackSize: subcatProduct.PackSizes[packIndex].PackSize,
            Description: subcatProduct.Description,
            ImgUrl: subcatProduct.PackSizes[packIndex].ImgUrlMbl,
            Price: subcatProduct.PackSizes[packIndex].SellingPrice,
            Qty: 1,
            TotalAmount: subcatProduct.PackSizes[packIndex].SellingPrice * 1,
            Mrp: subcatProduct.PackSizes[packIndex].Mrp,
            TotalPrice: subcatProduct.PackSizes[packIndex].SellingPrice * 1,
            TotalMrp: subcatProduct.PackSizes[packIndex].Mrp * 1,
            Discount:
                subcatProduct.PackSizes[packIndex].Mrp * 1 -
                subcatProduct.PackSizes[packIndex].SellingPrice * 1,
            Cashback: subcatProduct.Cashback,
            SellingPrice: subcatProduct.PackSizes[packIndex].SellingPrice,
            GstSellPrc: subcatProduct.PackSizes[packIndex].GstSellPrc,
            CostPrc: subcatProduct.PackSizes[packIndex].CostPrc,
            GstCost: subcatProduct.PackSizes[packIndex].GstCost,
            packsizeId: subcatProduct.PackSizes[packIndex]._id,
            maximumQuantity: subcatProduct.PackSizes[packIndex].maximumQuantity,
        };
        dispatch(addtoCart(formData));
    };
    console.log(currentSubCatProducts[0], 'currentSubCatProducts');
    const decrementtocartPress = async (e, hotPro, itemIndex, packIndex) => {
        try {
            let products = [...currentSubCatProducts];
            products[itemIndex].cartPackquentity[packIndex].CartQuantity =
                products[itemIndex].cartPackquentity[packIndex].CartQuantity - 1;

            setCurrentSubCatProducts([...products]);
        } catch (error) { }

        const formData = {
            ItemName: hotPro.ItemName,
            Category: hotPro.Category,
            CatId: hotPro.CatId,
            SubCat: hotPro.SubCat,
            SubCatId: hotPro.SubCatId,
            Brand: hotPro.Brand,
            PackSize: hotPro.PackSizes[packIndex].PackSize,
            SellingPrice: hotPro.PackSizes[packIndex].SellingPrice,
            GstSellPrc: hotPro.PackSizes[packIndex].GstSellPrc,
            Mrp: hotPro.PackSizes[packIndex].Mrp,
            CostPrc: hotPro.PackSizes[packIndex].CostPrc,
            totalMrp: hotPro.PackSizes[packIndex].Mrp * 1,
            totalPrice: hotPro.PackSizes[packIndex].SellingPrice * 1,
            totalAmount: hotPro.PackSizes[packIndex].SellingPrice * 1,
            GstCost: hotPro.PackSizes[packIndex].GstCost,
            Discount:
                hotPro.PackSizes[packIndex].Mrp * 1 -
                hotPro.PackSizes[packIndex].SellingPrice * 1,
            CartQuantity: 1,
            ImgUrlMbl: hotPro.PackSizes[packIndex].ImgUrlDesk,
            packsizeId: hotPro.PackSizes[packIndex]._id,
            productId: hotPro._id,
            maximumQuantity: hotPro.maximumQuantity,
        };
        dispatch(decrementCart(formData));
    };
    return (
        <>
            <section className="section-b-space shop-section">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-custome-12">
                            <div
                                className="row g-sm-4  row-cols-xxl-5 row-cols-xl-5 row-cols-lg-2 row-cols-md-3 row-cols-2 product-list-section"
                                style={{ background: "#f8f9fa" }}
                            >
                                {currentSubCatProducts &&
                                    currentSubCatProducts.map((product, itemIndex) => (
                                        <div
                                            className="product-box-3 h-100 wow fadeInUp p-2 "
                                            style={{ background: "#fff" }}
                                            key={itemIndex}
                                        >
                                            {product.PackSizes.map((pack, packIndex) =>
                                                pack.Pri === true ? (
                                                    <>
                                                        <div className="product-header">
                                                            <div className="product-image">
                                                                <Link to={`/product-info/${product.Url}`}>
                                                                    <a>
                                                                        <img
                                                                            src={pack.ImgUrlDesk}
                                                                            className="img-fluid blur-up lazyload"
                                                                            alt
                                                                        />
                                                                    </a>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="product-footer">
                                                            <div className="product-detail">
                                                                <span className="span-name">{product.Brand}</span>
                                                                <Link to={`/product-info/${product.Url}`}>
                                                                    <a>
                                                                        <h5 className="name">{product.ItemName}</h5>
                                                                    </a>
                                                                </Link>
                                                                <h6 className="unit"> {pack.PackSize}</h6>
                                                                <h5 className="price">
                                                                    <span className="theme-color">
                                                                        ₹{pack.SellingPrice}
                                                                    </span>
                                                                    {pack.Mrp === pack.SellingPrice ? (
                                                                        <> </>
                                                                    ) : (
                                                                        <>
                                                                            <del>₹{pack.Mrp}</del>
                                                                        </>
                                                                    )}
                                                                    <span style={{ paddingLeft: '2px', color: 'red' }}>(Offer Price)</span>
                                                                </h5>
                                                                <div className="add-to-cart-box bg-white mt-2">
                                                                    {pack.OutOfStack === true ? (
                                                                        <>
                                                                            {" "}
                                                                            <button className="btn btn-add-cart addcart-button">
                                                                                Out of stock
                                                                            </button>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {product.Multi === true ? (
                                                                                <>
                                                                                    <button
                                                                                        className="btn btn-add-cart addcart-button"
                                                                                        style={{ background: "#f8f9fa" }}
                                                                                        onClick={() =>
                                                                                            optionsProductset(product, itemIndex)
                                                                                        }
                                                                                    >
                                                                                        Options
                                                                                    </button>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {currentSubCatProducts[itemIndex]
                                                                                        ?.cartPackquentity[packIndex]?.CartQuantity >
                                                                                        0 ? (
                                                                                        <>
                                                                                            <div className="cart_qty qty-box open">
                                                                                                <div
                                                                                                    className="input-group bg-white"
                                                                                                // style={{ background: "#f8f9fa" }}
                                                                                                >
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="qty-left-minus bg-gray"
                                                                                                        data-type="minus"
                                                                                                        data-field
                                                                                                        onClick={(e) =>
                                                                                                            decrementtocartPress(
                                                                                                                e,
                                                                                                                product,
                                                                                                                itemIndex,
                                                                                                                packIndex
                                                                                                            )
                                                                                                        }
                                                                                                        style={{ background: "red" }}
                                                                                                    >
                                                                                                        <i
                                                                                                            className="fa fa-minus"
                                                                                                            aria-hidden="true"
                                                                                                        />
                                                                                                    </button>
                                                                                                    <input
                                                                                                        className="form-control input-number qty-input"
                                                                                                        type="text"
                                                                                                        name="quantity"
                                                                                                        defaultValue={0}
                                                                                                        value={
                                                                                                            currentSubCatProducts[itemIndex]
                                                                                                                .cartPackquentity[packIndex]
                                                                                                                .CartQuantity
                                                                                                        }
                                                                                                        contentEditable="false"
                                                                                                    />

                                                                                                    {11 >=
                                                                                                        currentSubCatProducts[itemIndex]
                                                                                                            .cartPackquentity[packIndex]
                                                                                                            .CartQuantity ? (
                                                                                                        <>
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="qty-right-plus bg-gray"
                                                                                                                data-type="plus"
                                                                                                                data-field
                                                                                                                onClick={(e) =>
                                                                                                                    addtocartPress(
                                                                                                                        e,
                                                                                                                        product,
                                                                                                                        itemIndex,
                                                                                                                        packIndex
                                                                                                                    )
                                                                                                                }
                                                                                                            >
                                                                                                                <i
                                                                                                                    className="fa fa-plus"
                                                                                                                    aria-hidden="true"
                                                                                                                />
                                                                                                            </button>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="qty-right-plus bg-gray"
                                                                                                                data-type="plus"
                                                                                                                data-field
                                                                                                            >
                                                                                                                <i
                                                                                                                    className="fa fa-plus"
                                                                                                                    aria-hidden="true"
                                                                                                                />
                                                                                                            </button>
                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <button
                                                                                                className="btn btn-add-cart addcart-button"
                                                                                                style={{ background: "#f8f9fa" }}
                                                                                                onClick={(e) =>
                                                                                                    addtocartPress(
                                                                                                        e,
                                                                                                        product,
                                                                                                        itemIndex,
                                                                                                        packIndex
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                Add
                                                                                                <span
                                                                                                    className="add-icon bg-light-gray"
                                                                                                    style={{ background: "#fff" }}
                                                                                                >
                                                                                                    <i className="fa-solid fa-plus" />
                                                                                                </span>
                                                                                            </button>
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div key={packIndex}></div>
                                                    </>
                                                )
                                            )}
                                        </div>
                                    ))}
                            </div>
                            <Modal
                                size="md"
                                // centered
                                isOpen={visible}
                                toggle={() => setVisible(!visible)}
                                modalTransition={{ timeout: 500 }}
                            >
                                <ModalBody>
                                    <h3>{currentProduct.ItemName}</h3>
                                    <h5>{currentProduct.Brand}</h5>
                                    <div className="col-12 mt-3 ">
                                        {currentProduct &&
                                            currentProduct.PackSizes.map((packsize, packIndex) => (
                                                <div
                                                    className="col-12 d-flex justify-content-between my-2"
                                                    key={packsize._id}
                                                >
                                                    <div className="col-2">
                                                        <img
                                                            src={packsize.ImgUrlDesk}
                                                            className="img-fluid blur-up lazyload"
                                                            alt="image"
                                                            width={200}
                                                        />
                                                    </div>
                                                    <h6
                                                        className="col-1 d-flex justify-content-center align-items-center "
                                                        style={{
                                                            color: "#0e947a",
                                                            fontSize: "14px",
                                                            marginLeft: "2vh",
                                                        }}
                                                    >
                                                        {packsize.PackSize}
                                                    </h6>
                                                    <h6
                                                        className="col-3 d-flex justify-content-center align-items-center "
                                                        style={{
                                                            color: "#0e947a",
                                                            fontSize: "14px",
                                                            marginLeft: "2vh",
                                                        }}
                                                    >
                                                        ₹{packsize.SellingPrice}
                                                        {packsize.Mrp === packsize.SellingPrice ? (
                                                            <> </>
                                                        ) : (
                                                            <>
                                                                <span
                                                                    style={{
                                                                        color: "#000",
                                                                        fontSize: "12px",
                                                                        textDecorationLine: "line-through",
                                                                        marginLeft: "0.5vh",
                                                                    }}
                                                                >
                                                                    ₹{packsize.Mrp}
                                                                </span>
                                                            </>
                                                        )}
                                                    </h6>
                                                    <div className="col-3 d-flex justify-content-end align-items-center">
                                                        {packsize.OutOfStack === true ? (
                                                            <>
                                                                {" "}
                                                                <Button
                                                                    variant="contained"
                                                                    style={{
                                                                        color: "#fff",
                                                                        background: "#0e947a",
                                                                        // fontSize:"15px"
                                                                    }}
                                                                    className="mx-1"
                                                                >
                                                                    out of stock
                                                                </Button>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {currentProduct.cartPackquentity[packIndex]
                                                                    .CartQuantity > 0 ? (
                                                                    <>
                                                                        <div className="addtocart_btn">
                                                                            <div className="qty-box cart_qty open">
                                                                                <div className="input-group">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn qty-left-minus"
                                                                                        data-type="minus"
                                                                                        data-field
                                                                                        onClick={(e) =>
                                                                                            decrementtocartPress(
                                                                                                e,
                                                                                                currentProduct,
                                                                                                curritemIndex,
                                                                                                packIndex
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <i
                                                                                            className="fa fa-minus"
                                                                                            aria-hidden="true"
                                                                                        />
                                                                                    </button>

                                                                                    <input
                                                                                        className="form-control input-number qty-input mx-2"
                                                                                        type="text"
                                                                                        name="quantity"
                                                                                        defaultValue={0}
                                                                                        value={
                                                                                            currentProduct.cartPackquentity[packIndex]
                                                                                                .CartQuantity
                                                                                        }
                                                                                        readonly="readonly"
                                                                                    />

                                                                                    {11 ===
                                                                                        currentProduct.cartPackquentity[packIndex]
                                                                                            .CartQuantity ? (
                                                                                        <>
                                                                                            {" "}
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn qty-left-plus"
                                                                                                data-type="plus"
                                                                                                data-field
                                                                                            >
                                                                                                <i
                                                                                                    className="fa fa-plus"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                            </button>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            {" "}
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn qty-left-plus"
                                                                                                data-type="plus"
                                                                                                data-field
                                                                                                onClick={(e) =>
                                                                                                    addtocartPress(
                                                                                                        e,
                                                                                                        currentProduct,
                                                                                                        curritemIndex,
                                                                                                        packIndex
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <i
                                                                                                    className="fa fa-plus"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                            </button>
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Button
                                                                            variant="contained"
                                                                            style={{
                                                                                color: "#fff",
                                                                                background: "#0e947a",
                                                                            }}
                                                                            className="mx-1"
                                                                            onClick={(e) =>
                                                                                addtocartPress(
                                                                                    e,
                                                                                    currentProduct,
                                                                                    curritemIndex,
                                                                                    packIndex
                                                                                )
                                                                            }
                                                                        >
                                                                            Add
                                                                            <span className="mx-1">
                                                                                <i className="fa-solid fa-plus" />
                                                                            </span>
                                                                        </Button>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </ModalBody>
                            </Modal>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CatOfferPage;
