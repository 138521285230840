import React, { useEffect } from "react";
import { FiMinus } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { IoIosArrowForward } from "react-icons/io";
import { BiSolidOffer } from "react-icons/bi";
import { FaLock } from "react-icons/fa6";
import { FaMoneyBillWave } from "react-icons/fa";
import { FaRegCommentDots } from "react-icons/fa";
import { TbBrandPaypal } from "react-icons/tb";
import CartSlider from "../../component/cartPart/CartSlider";
import CartFooter from "../../component/cartPart/CartFooter";
import HotProducts from "../Home/HotProducts";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  addtoCart,
  decrementCart,
  getCartTotal,
  removefromCart,
} from "../../redux/cart/CartSlice";

const CartDemo = () => {
  const { CartItems, cartTotalAmount, ProductTotal } = useSelector(
    (state) => state.Cart
  );

  const {
    address,
    currentAddress,
    addressmodel,
    loginData,
    clientWalletAmount,
  } = useSelector((state) => state.Athentication);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCartTotal());
  }, [CartItems, dispatch]);

  const removefromcart = (cartItem) => {
    dispatch(removefromCart(cartItem));
    dispatch(getCartTotal());
  };

  const handledecreasequtcart = (cartItem) => {
    dispatch(decrementCart(cartItem));
    dispatch(getCartTotal());
  };

  const handleincreasequtcart = (cartItem) => {
    dispatch(addtoCart(cartItem));
    dispatch(getCartTotal());
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          //   boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          margin: "5px",
          padding: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <BiSolidOffer size={25} color="#0e947a" />
            <h5 style={{ paddingLeft: "6px", fontWeight: "600" }}>
              View Coupons & Offers
            </h5>
          </div>
          <IoIosArrowForward size={20} color="#0e947a" />
        </div>
          </div>
          {
              CartItems &&
              CartItems.map((cart, index) => (
            //     <div className="cartcontainermain">
            //     <div
            //       style={{
            //         display: "flex",
            //         justifyContent: "space-between",
            //         alignItems: "center",
            //         padding: "10PX",
            //       }}
            //     >
            //       <div>
            //         <img
            //           src={cart.ImgUrl}
            //           alt="image"
            //           height="50px"
            //           width="60px"
            //         />
            //       </div>
            //       <div style={{ width: "200px",paddingLeft:"8px"}}>
            //         <h6>{cart.ItemName}</h6>
            //                   <p>{cart.PackSize}</p>
            //       </div>
            //       <div
            //         style={{
            //           display: "flex",
            //           width: "50px",
            //           justifyContent: "space-between",
            //           alignItems: "center",
            //           height: "30px",
            //           backgroundColor: "#0e947a",
            //           color: "#fff",
            //           borderRadius: "5px",
            //           padding: "2PX",
            //         }}
            //       >
            //         <FiMinus onClick={() => handledecreasequtcart(cart)}/>
            //         <span>{cart.Qty}</span>
            //         <GoPlus  onClick={() => handleincreasequtcart(cart)}/>
            //       </div>
            //       <div
            //         style={{
            //           display: "flex",
            //           justifyContent: "center",
            //           alignItems: "center",
            //         }}
            //       >
            //         <LiaRupeeSignSolid />
            //         <span>{cart.Price}</span>
            //       </div>
            //     </div>
            //     <div
            //       style={{
            //         display: "flex",
            //         justifyContent: "space-between",
            //         alignItems: "center",
            //         padding: "10PX",
            //       }}
            //     >
            //       <div>
            //         <img
            //           src={cart.ImgUrl}
            //           alt="image"
            //           height="50px"
            //           width="60px"
            //         />
            //       </div>
            //       <div style={{ width: "200px",paddingLeft:"8px" }}>
            //         <h6>{cart.ItemName}</h6>
            //                   <p>{cart.Qty}</p>
            //       </div>
            //       <div
            //         style={{
            //           border: "1px solid #0e947a",
            //           height: "30px",
            //           padding: "3px",
            //           borderRadius: "5px",
            //         }}
            //       >
            //         <p style={{ color: "#0e947a", fontWeight: "600",cursor: "pointer"  }}  onClick={() => removefromcart(cart)}
            //          >Remove</p>
            //       </div>
            //       <div
            //         style={{
            //           display: "flex",
            //           justifyContent: "center",
            //           alignItems: "center",
            //         }}
            //       >
            //         <LiaRupeeSignSolid />
            //         <span>0</span>
            //       </div>
            //     </div>
                  //   </div>
                  <div className="swipersubcontainer1" style={{margin:"10px",width:"95%"}}>
                  <div className="swipersubcontainer2">
                    <div>
                      <img
                        src={cart.ImgUrl}
                        alt="image"
                        height="40px"
                        width="40px"
                      />
                    </div>
                    <div className="swipersubcontainer3">
                      <div>
                        <h6 style={{ fontWeight: "600" }}>
                         {cart.ItemName}
                        </h6>
                        <span>{cart.PackSize}</span>
                      </div>
                    </div>
                  </div>
                  <div className="swipersubcontainer2">
                    {/* <div className="sliderbtn">
                      <p style={{ color: "red", fontWeight: "600" }}>Unlock</p>
                    </div> */}
                            <div
                    style={{
                      display: "flex",
                      width: "80px",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "30px",
                      backgroundColor: "#0e947a",
                      color: "#fff",
                      borderRadius: "5px",
                      padding: "2PX",
                    }}
                  >
                    <FiMinus onClick={() => handledecreasequtcart(cart)}/>
                    <span>{cart.Qty}</span>
                    <GoPlus  onClick={() => handleincreasequtcart(cart)}/>
               </div>
                    <div style={{ width: "40px" }}>
                      <div className="sliderlastbtn">
                        <LiaRupeeSignSolid />
                        <span>{cart.Price}</span>
                      </div>
                      <div className="sliderlastbtn1">
                        <LiaRupeeSignSolid />
                        <span style={{ textDecorationLine: "line-through" }}>
                        {cart.Mrp}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
          }
     
      <CartSlider />
      {/* <HotProducts /> */}
      {/* <CartFooter /> */}
    </>
  );
};

export default CartDemo;
