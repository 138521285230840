import React, { useEffect, useState } from "react";
import Header from "./cart/Header";
import CartProducts from "./cart/CartProducts";
import CartCheckOut from "./cart/CartCheckOut";
import EmptyCart from "./cart/EmptyCart";

import { useDispatch, useSelector } from "react-redux";
import CartDemo from "./cart/CartDemo";
import { getCartTotal } from "../redux/cart/CartSlice";

const Cart = () => {
  const dispatch=useDispatch()
  const { CartItems } = useSelector((state) => state.Cart);
  useEffect(() => {
    dispatch(getCartTotal())
  }, [])
  
  return (
    <>
      {CartItems.length === 0 ? (
        <>
          <EmptyCart />{" "}
        </>
      ) : (
        <>
          {/* <Header /> */}
          <section className="cart-section section-b-space">
            <div className="container-fluid-lg">
              <div className="row g-sm-5 g-3">
                  <CartProducts />
                  {/* <CartDemo /> */}
                <CartCheckOut />
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Cart;
