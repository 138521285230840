import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Baseurl } from "../config/BaseUrl";
import axios from "axios";
import HTBSubCategorySection from "./HomeTopBanner/HTBSubCategorySection";
import HTBProductSection from "./HomeTopBanner/HTBProductSection";
import BeatLoader from "react-spinners/BeatLoader";

const HTBMainPage = () => {
  const { shopbyCategory } = useSelector((state) => state.Category);
  const { CartItems, ProductTotal, ProductLoading } = useSelector(
    (state) => state.Cart
  );
  const [currentCatProduct, setCurrentCatProduct] = useState([]);
  const [showProduct, setShowProduct] = useState([]);
  const [subCatId, setSubCatId] = useState("");
  const [catId, setCatId] = useState("");

  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const caterl = params.htsurl;
    const currCatId = shopbyCategory.find((cat) => cat.name === caterl)._id;

    setCatId(currCatId);
    if (!ProductLoading) {
      let catpro = ProductTotal.filter(
        (product) => product.CatId === currCatId
      );

      let fetchQntyproducts = catpro.map((product) => {
        let cartPackquentity = [];
        let packqnty = {};

        for (let index = 0; index < product.PackSizes.length; index++) {
          const element = product.PackSizes[index];
          packqnty = {
            packId: element._id,
            CartQuantity: 0,
            Pri: element.Pri,
          };
          cartPackquentity = [...cartPackquentity, packqnty];
          cartPackquentity = [...cartPackquentity];
        }
        product = { ...product, cartPackquentity };
        return product;
      });

      CartItems.forEach((cartitem) => {
        let itemIndex = -1;
        itemIndex = fetchQntyproducts.findIndex(
          (product) => String(product._id) === String(cartitem.ProductId)
        );

        if (itemIndex >= 0) {
          let packIndex = -1;

          packIndex = fetchQntyproducts[itemIndex].cartPackquentity.findIndex(
            (pack) => String(pack.packId) === String(cartitem.packsizeId)
          );

          fetchQntyproducts[itemIndex].cartPackquentity[
            packIndex
          ].CartQuantity = 1;
          if (packIndex >= 0) {
            fetchQntyproducts[itemIndex].cartPackquentity[
              packIndex
            ].CartQuantity = 1;
          }
        }
      });
      setShowProduct(fetchQntyproducts);
      setCurrentCatProduct(fetchQntyproducts);
    }
  }, [params.htsurl, ProductLoading]);

  const subCatChange = (currentsubCatId) => {
    setSubCatId(currentsubCatId);
    const currentSubCatproducts = currentCatProduct.filter(
      (subC) => subC.SubCatId === currentsubCatId
    );
    setShowProduct(currentSubCatproducts);
  };
  return (
    <>
      <section className="section-b-space shop-section">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-custome-3">
              <HTBSubCategorySection
                onFocus={subCatChange}
                categoryId={catId}
              />
            </div>
            <div className="col-custome-9">
              {ProductLoading ? (
                <>
                  <div className="d-flex justify-content-center loadingMain">
                    <h4 style={{ fontWeight: "bold", color: "#36d7b7" }}>
                      Product is being fetched
                    </h4>
                    <BeatLoader
                      color={"#36d7b7"}
                      loading={ProductLoading}
                      size={10}
                      className="mt-2 mx-2"
                    />
                  </div>
                </>
              ) : (
                <>
                  <HTBProductSection curShowProducts={showProduct} />
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HTBMainPage;
