import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MobileVerify from "./deleteAccount/MobileVerify";
import OtpVerify from "./deleteAccount/OtpVerify";
import AccoutDetails from "./deleteAccount/AccoutDetails";

const DeleteAccount = () => {
  const { deletepageStatus } = useSelector((state) => state.Athentication);

  // console.log(deletepageStatus, "deletepageStatus");

  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return <MobileVerify />;
      case 1:
        return <OtpVerify />;
      case 2:
        return <AccoutDetails />;
      default:
        return <MobileVerify />;
        break;
    }
  };

  return <>{renderSwitch(deletepageStatus)}</>;
};

export default DeleteAccount;
