import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import {
  clientUpdate,
  getClientByMob,
  walletTransitionPost,
} from "../../redux/athentication/Athentication";
import {
  gerenatenewTokrnId,
  getOrderbyClId,
  updateOrder,
} from "../../redux/order/OrderSlice";
import { toast } from "react-toastify";
import axios from "axios";

const MyProfile = () => {
  const { loginData, clientid, deleteClientDetails } = useSelector(
    (store) => store.Athentication
  );
  console.log(loginData,"1");
  console.log(deleteClientDetails,"2");
  
  const { clientOrder } = useSelector((state) => state.Order);

  const dispatch = useDispatch();

  const [model, setModel] = useState(false);

  return (
    <>
      <div
        className="tab-pane fade show active"
        id="pills-dashboard"
        role="tabpanel"
        aria-labelledby="pills-dashboard-tab"
      >
        <div className="dashboard-home">
          <div className="d-flex justify-content-between">
            <div className="title pt-2">
              <h2>Account Details</h2>
            </div>
          </div>

          <div className="profile-tab dashboard-bg-box">
            <div className="dashboard-title dashboard-flex">
              <h3>Profile Name</h3>
            </div>
            <ul>
              <li className="d-flex">
                <h5>Name :</h5>
                <h5>{deleteClientDetails.Name}</h5>
              </li>
              <li className="d-flex">
                <h5>Email Address :</h5>
                <h5>{deleteClientDetails.Email}</h5>
              </li>
              <li className="d-flex">
                <h5>Country / Region :</h5>
                <h5>India</h5>
              </li>
              <li className="d-flex">
                <h5>Year Registared :</h5>
                <h5>{moment(deleteClientDetails.createdAt).format("YYYY")}</h5>
              </li>
            </ul>
          </div>
        </div>

        {/* <section className="product-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 wow fadeInUp">
                <div className="row g-4">
                  <div className="col-12">
                    <div className="product-section-box">
                      <h2>ORDERS</h2>
                      <div className="tab-content custom-tab" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="description"
                          role="tabpanel"
                          aria-labelledby="description-tab"
                        >
                          <div className="dashboard-order">
                            {clientOrder.length > 0 ? (
                              <>
                                <div className="order-contain">
                                  {clientOrder.map((order, index) => (
                                    <div
                                      className="order-box dashboard-bg-box"
                                      key={index}
                                    >
                                      <div className="order-container">
                                        <Link
                                          to={`/ordertracking/${order._id}`}
                                        >
                                          <div className="order-icon">
                                            <img
                                              src="../assets/images/cart.png"
                                              alt="img"
                                              width={100}
                                            />
                                          </div>
                                        </Link>
                                        <Link
                                          to={`/ordertracking/${order._id}`}
                                        >
                                          <div className="order-detail">
                                            <h4>
                                              <h5 className="success-bg me-2  text-success">
                                                Net Payable:
                                              </h5>
                                              <h5 style={{ color: "red" }}>
                                                ₹{order.GrandTotal}
                                              </h5>
                                            </h4>
                                            <h4>
                                              <h5 className="success-bg me-2  text-success">
                                                Order Status:
                                              </h5>
                                              <h5 style={{ color: "red" }}>
                                                {order.StatusText}
                                              </h5>
                                            </h4>

                                            <h4>
                                              <h5 className="success-bg me-2  text-success">
                                                Payment Type:
                                              </h5>
                                              <h5 style={{ color: "red" }}>
                                                {order.PaymentMode}
                                              </h5>
                                            </h4>

                                            <h4>
                                              <h5 className="success-bg me-2  text-success">
                                                Payment Status:
                                              </h5>
                                              <h5 style={{ color: "red" }}>
                                                {order.CurrentStatus}
                                              </h5>
                                            </h4>

                                            {order.CurrentStatus === "Paid" ? (
                                              <> </>
                                            ) : (
                                              <></>
                                            )}

                                            <h4>
                                              <h5 className="success-bg me-2  text-success">
                                                Transaction Status:
                                              </h5>
                                              <h5 style={{ color: "red" }}>
                                                {order.PaymentStatus}
                                              </h5>
                                            </h4>

                                            <h4>
                                              <h5 className="success-bg me-2 text-success">
                                                Order Date:
                                              </h5>
                                              <h5 style={{ color: "red" }}>
                                                {moment(order.createdAt).format(
                                                  "MMM DD YY"
                                                )}
                                              </h5>
                                            </h4>
                                            <h4>
                                              <h5 className="success-bg me-2 text-success">
                                                Expected Delivery Date:
                                              </h5>
                                              <h5 style={{ color: "red" }}>
                                                {moment(
                                                  order.ExpectedDelDate
                                                ).format("MMM DD YY")}
                                              </h5>
                                            </h4>
                                          </div>
                                        </Link>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="order-contain">
                                  <section className="section-404 section-lg-space">
                                    <div className="container-fluid-lg">
                                      <div className="row">
                                        <div className="col-12">
                                          <div className="image-404">
                                            <img
                                              src="../assets/images/inner-page/emptycart.png"
                                              className="img-fluid blur-up lazyload"
                                              alt
                                            />
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="contain-404">
                                            <Link to="/">
                                              <button className="btn btn-md text-white theme-bg-color mt-4 mx-auto">
                                                Back To Home Screen
                                              </button>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    </>
  );
};

export default MyProfile;
