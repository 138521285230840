import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CheckoutHeader from "./CheckOut/CheckoutHeader";
import LeftSideCheckout from "./CheckOut/LeftSideCheckout";
import RightSideCheckOut from "./CheckOut/RightSideCheckOut";
import { walletamountUpdate } from "../redux/cart/CartSlice";
import { walletUseUpdate } from "../redux/athentication/Athentication";

const CheckOut = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(walletamountUpdate(0));
    dispatch(walletUseUpdate(false));
  }, []);

  return (
    <>
      {/* <CheckoutHeader /> */}
      <section className="checkout-section-2 section-b-space">
        <div className="container-fluid-lg">
          <div className="row g-sm-4 g-3">
            <LeftSideCheckout />
            <RightSideCheckOut />
          </div>
        </div>
      </section>
    </>
  );
};

export default CheckOut;
