import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

const SubCatSection = (props) => {
  const { subCategoryTotal } = useSelector((state) => state.SubCategory);
  const { shopbyCategory } = useSelector((state) => state.Category);
  const [SubCategoryList, setSubCategoryList] = useState([]);
  const [curresubctCategory, setCurresubctCategory] = useState("");
  const [currentCategoryUrl, setCurrentCategoryUrl] = useState("");

  useEffect(() => {
    const currentCatId = props.categoryId;
    const currentSubCat = subCategoryTotal.filter(
      (subC) => subC.categoryId === currentCatId
    );
    setCurresubctCategory(props.subcateory);
    setCurrentCategoryUrl(props.categoryUrl);
    setSubCategoryList(currentSubCat);
  }, [props.categoryId, props.subcateory, props.categoryUrl]);

  return (
    <>
      <div className="left-box wow fadeInUp">
        <div className="shop-left-sidebar">
          <div className="back-button">
            <h3>
              <i className="fa-solid fa-arrow-left" /> Back
            </h3>
          </div>

          <div className="accordion custome-accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <span>Sub Categories</span>
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
              >
                <div className="accordion-body">
                  <ul className="category-list custom-padding custom-height">
                    {SubCategoryList.map((subcat, index) => (
                      <li key={index}>
                        <div className="form-check ps-0 m-0 category-list-box">
                          {/* <input
                            className="checkbox_animated"
                            type="checkbox"
                            id="fruit"
                          /> */}
                          <img
                            height={35}
                            src={subcat.desktopImage}
                            alt="img"
                          />

                          <label className="form-check-label" htmlFor="fruit">
                            <Link
                              to={`/${currentCategoryUrl}/${subcat.slugUrl}`}
                            >
                              <span
                                className={
                                  curresubctCategory._id === subcat._id
                                    ? "nameActive"
                                    : "name"
                                }
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  props.onFocus(subcat._id);
                                  setCurresubctCategory(subcat);
                                }}
                              >
                                {subcat.name}
                              </span>
                            </Link>

                            {/* <span className="number">(15)</span> */}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubCatSection;
