import React, { useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import { Modal, ModalHeader } from "reactstrap";
import EditAdress from "./address/EditAddress";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";
import {
  addressModelStatus,
  currAdress,
  editaddressModelStatus,
  noaddressUpdate,
  updateAddress,
} from "../../../redux/athentication/Athentication";
import { Baseurl } from "../../../config/BaseUrl";
import AddAddress from "./address/AddAddress";

const CheckoutAddrs = () => {
  const { currentAddress, address, addressmodel, editaddressmodel, clientid } =
    useSelector((store) => store.Athentication);

  const [model, setModel] = useState(false);
  const [editmodel, setEditModel] = useState(false);
  const [editableAddress, setEditableAddress] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   const deleteClick = async (e, id) => {
  //     const data = await dispatch(addressDelete(id));
  //   };

  useEffect(() => {}, []);

  const editaddress = (e, data) => {
    const formData = {
      address: data,
    };
    setEditableAddress(formData);
    dispatch(editaddressModelStatus(true));
  };

  const selectclintddress = (evt, data) => {
    dispatch(currAdress(data));
  };

  const deleteClick = async (e, addressId) => {
    e.preventDefault();
    try {
      const formData = {
        ClientId: clientid,
        addressId: addressId,
      };
      const config = {
        Headers: { "Content-Type": "application/json" },
      };

      const url = `${Baseurl}/api/v1/client/deleteclientsingleaddress`;
      const addAddress = await axios.put(url, formData, config);

      if (addAddress.data.success) {
        const lastaddress = addAddress.data.client;
        const currentAddress = lastaddress.Addresses;
        dispatch(updateAddress(currentAddress));
        if (currentAddress.length === 0) {
          dispatch(noaddressUpdate());
        }
      }
    } catch (error) {}
  };

  return (
    <>
      <div
        className="tab-pane fade show"
        id="pills-address"
        role="tabpanel"
        aria-labelledby="pills-address-tab"
      >
        <div className="dashboard-address">
          <Modal
            size="md"
            isOpen={addressmodel}
            toggle={() => dispatch(addressModelStatus(true))}
          >
            <ModalHeader>
              <AddAddress />
            </ModalHeader>
          </Modal>

          <Modal
            size="md"
            isOpen={editaddressmodel}
            toggle={() => dispatch(editaddressModelStatus(true))}
          >
            <ModalHeader>
              <EditAdress addressobject={editableAddress} />
            </ModalHeader>
          </Modal>

          <div className="title title-flex">
            <div>
              <h2>My Addresses</h2>
              <span className="title-leaf">
                <svg className="icon-width bg-gray">
                  <use xlinkHref="https://themes.pixelstrap.com/fastkart/assets/svg/leaf.svg#leaf" />
                </svg>
              </span>
            </div>
            <button
              className="btn theme-bg-color text-white btn-sm fw-bold mt-lg-0 mt-3"
              data-bs-toggle="modal"
              data-bs-target="#add-address"
              onClick={() => dispatch(addressModelStatus(true))}
            >
              <i data-feather="plus" className="me-2" /> Add New Address
            </button>
          </div>
          <div className="row g-sm-4 g-3">
            {address &&
              address.map((data, index) => (
                <div
                  className="col-xxl-4 col-xl-6 col-lg-12 col-md-6 p-1"
                  key={index}
                >
                  <div
                    className=""
                    style={{
                      borderRadius: "8px",
                      backgroundColor: "#fff",
                      boxShadow: "0 0 9px rgba(0,0,0,0.07)",
                      position: "relative",
                      height: "100%",
                      overflow: "hidden",
                      marginTop:"15px"
                    }}
                  >
                    <div
                      style={{
                        display: "-ms-flexbox",
                        display: "flex",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="jack"
                            id="flexRadioDefault2"
                            checked={currentAddress._id === data._id}
                            onChange={(e) => {
                              selectclintddress(e, data);
                            }}
                            value={data._id}
                            style={{backgroundColor:"#0e947a",borderColor:"#e947a", marginLeft:"1px",marginTop:"5px"}}
                          />
                        </div>
                        <div className=""  style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            backgroundColor: "var(--theme-color)",
                            padding: "2px 8px",
                            borderRadius: "4px",
                            color: "#fff",
                            fontSize: "12px",
                            letterSpacing: ".8px",
                            margin:"10px"
                          }}>
                          <label>{data.Type}</label>
                        </div>
                      </div>

                      <div className="table-responsive address-table">
                        <table className="table">
                          <tbody>
                            <tr>
                              <td colSpan={2}></td>
                            </tr>
                            <tr>
                              <td>Address :</td>
                              <td>
                                <p>{data.HNo + "," + data.StreetDet}</p>
                              </td>
                            </tr>
                            <tr>
                              <td>Pin Code :</td>
                              <td>{data.Pincode}</td>
                            </tr>
                            <tr>
                              <td>Phone :</td>
                              <td>+91- {data.Number}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className=""
                      style={{
                        display: "-webkit-box",
                        display: "-ms-flexbox",
                        display: "flex",
                        warp: "nowrap",
                        gap: "10px",
                        alignItems: "center",
                        marginBottom:"10px"
                      }}>
                      <button
                        className="btn btn-sm add-button w-100"
                        data-bs-toggle="modal"
                        data-bs-target="#editProfile"
                        onClick={(e) => editaddress(e, data)}
                        style={{
                          backgroundColor: "#f8f8f8",
                          fontWeight: "600",
                        }}
                      >
                        <BiEditAlt data-feather="edit" />
                        Edit
                      </button>
                      <button
                        className="btn btn-sm add-button w-100"
                        onClick={(e) => deleteClick(e, data._id)}
                        style={{
                          backgroundColor: "#f8f8f8",
                          fontWeight: "600",
                        }}
                      >
                        <MdDeleteOutline data-feather="trash-2" />
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="bottompadd"></div>
    </>
  );
};

export default CheckoutAddrs;
