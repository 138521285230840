import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";
import SubCatSection from "../subcategoeyMain/SubCatSection";
import SubcatProduct from "../subcategoeyMain/SubcatProduct";
import { Baseurl3 } from "../../config/BaseUrl";

const Subcategoey = () => {
  const { shopbyCategory } = useSelector((state) => state.Category);
  const { subCategoryTotal } = useSelector((state) => state.SubCategory);
  const { CartItems, ProductTotal, ProductLoading } = useSelector(
    (state) => state.Cart
  );
  const [currentCatProduct, setCurrentCatProduct] = useState([]);
  const [showProduct, setShowProduct] = useState([]);
  const [subCatId, setSubCatId] = useState("");
  const [subCat, setSubCat] = useState("");
  const [catId, setCatId] = useState("");
  const [temploading, setTempLoading] = useState(true);

  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const caterl = params.caturl;
    const subcaturl = params.subcat;

    let currCatId = shopbyCategory.find((cat) => cat.slugUrl === caterl)._id;

    setCatId(currCatId);

    const fetchData = async () => {
      const url = `${Baseurl3}/api/v1/subcategory/subcatbyurl/${params.subcat}`;
      const fetchSubcat = await axios.get(url);
      try {
        if (fetchSubcat.data.success === true) {
          const subcat = fetchSubcat.data.subcategory;
          let currsubCatId = subcat._id;
          setSubCat(subcat);
          if (!ProductLoading) {
            let catpro = ProductTotal.filter(
              (product) => product.SubCatId === currsubCatId
            );

            let fetchQntyproducts = catpro.map((product) => {
              let cartPackquentity = [];
              let packqnty = {};

              for (let index = 0; index < product.PackSizes.length; index++) {
                const element = product.PackSizes[index];
                packqnty = {
                  packId: element._id,
                  CartQuantity: 0,
                  Pri: element.Pri,
                };
                cartPackquentity = [...cartPackquentity, packqnty];
                cartPackquentity = [...cartPackquentity];
              }
              product = { ...product, cartPackquentity };
              return product;
            });

            CartItems.forEach((cartitem) => {
              let itemIndex = -1;
              itemIndex = fetchQntyproducts.findIndex(
                (product) => String(product._id) === String(cartitem.ProductId)
              );

              if (itemIndex >= 0) {
                let packIndex = -1;

                packIndex = fetchQntyproducts[
                  itemIndex
                ].cartPackquentity.findIndex(
                  (pack) => String(pack.packId) === String(cartitem.packsizeId)
                );

                fetchQntyproducts[itemIndex].cartPackquentity[
                  packIndex
                ].CartQuantity = 1;
                if (packIndex >= 0) {
                  fetchQntyproducts[itemIndex].cartPackquentity[
                    packIndex
                  ].CartQuantity = 1;
                }
              }
            });
            setShowProduct(fetchQntyproducts);
            setCurrentCatProduct(fetchQntyproducts);
          } else {
            const fetchData = async () => {
              const url = `${Baseurl3}/api/v1/grocery/grocerybysubcatid/${currsubCatId}`;
              const fetchproducts = await axios.get(url);
              try {
                if (fetchproducts.data.success === true) {
                  setTempLoading(false);
                  const prod = fetchproducts.data.grocery;

                  let fetchQntyproducts = prod.map((product) => {
                    let cartPackquentity = [];
                    let packqnty = {};

                    for (
                      let index = 0;
                      index < product.PackSizes.length;
                      index++
                    ) {
                      const element = product.PackSizes[index];
                      packqnty = {
                        packId: element._id,
                        CartQuantity: 0,
                        Pri: element.Pri,
                      };
                      cartPackquentity = [...cartPackquentity, packqnty];
                      cartPackquentity = [...cartPackquentity];
                    }
                    product = { ...product, cartPackquentity };
                    return product;
                  });

                  CartItems.forEach((cartitem) => {
                    let itemIndex = -1;
                    itemIndex = fetchQntyproducts.findIndex(
                      (product) =>
                        String(product._id) === String(cartitem.ProductId)
                    );

                    if (itemIndex >= 0) {
                      let packIndex = -1;

                      packIndex = fetchQntyproducts[
                        itemIndex
                      ].cartPackquentity.findIndex(
                        (pack) =>
                          String(pack.packId) === String(cartitem.packsizeId)
                      );

                      fetchQntyproducts[itemIndex].cartPackquentity[
                        packIndex
                      ].CartQuantity = 1;
                      if (packIndex >= 0) {
                        fetchQntyproducts[itemIndex].cartPackquentity[
                          packIndex
                        ].CartQuantity = 1;
                      }
                    }
                  });
                  setShowProduct(fetchQntyproducts);
                  setCurrentCatProduct(fetchQntyproducts);
                }
              } catch (error) {}
            };
            fetchData();
          }
        }
      } catch (error) {}
    };
    fetchData();
  }, [params.caturl, params.subcat, ProductLoading]);

  const subCatChange = (currentsubCatId) => {
    setSubCatId(currentsubCatId);
    const currentSubCatproducts = currentCatProduct.filter(
      (subC) => subC.SubCatId === currentsubCatId
    );
    setShowProduct(currentSubCatproducts);
  };

  return (
    <section className="section-b-space shop-section">
      <div className="container-fluid-lg">
        <div className="row">
          <div className="col-custome-3">
            <SubCatSection
              onFocus={subCatChange}
              categoryUrl={params.caturl}
              categoryId={catId}
              subcateory={subCat}
            />
          </div>
          <div className="col-custome-9">
            {ProductLoading ? (
              <>
                {temploading ? (
                  <>
                    <div className="d-flex justify-content-center loadingMain">
                      <h4 style={{ fontWeight: "bold", color: "#36d7b7" }}>
                        Product is being fetched
                      </h4>
                      <BeatLoader
                        color={"#36d7b7"}
                        loading={ProductLoading}
                        size={10}
                        className="mt-2 mx-2"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <SubcatProduct curShowProducts={showProduct} />
                  </>
                )}
              </>
            ) : (
              <>
                <SubcatProduct curShowProducts={showProduct} />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subcategoey;
