import React, { useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import { Modal, ModalHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  addressModelStatus,
  currAdress,
  editaddressModelStatus,
  noaddressUpdate,
  signout,
  updateAddress,
} from "../../redux/athentication/Athentication";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";

const MyAddress = () => {
  const { deleteClientDetails } = useSelector((store) => store.Athentication);

  console.log(deleteClientDetails, "deleteClientDetails");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDeleteAccount = async () => {
    try {
      const formData = {
        ClientId: deleteClientDetails._id,
      };
      const config = {
        Headers: { "Content-Type": "application/json" },
      };

      const url = `${Baseurl}/api/v1/client/clientdelete/${deleteClientDetails._id}`;
      const resp = await axios.delete(url, formData, config);
      if (resp.data.success) {
        dispatch(signout());
        toast.success("Account Deleted Successfully", {
          position: "top-center",
          autoClose: 500,
        });
        navigate("/delete-success");
      }
    } catch (error) {}
  };

  return (
    <>
      {deleteClientDetails.Addresses.length > 0 ? (
        <>
          <div
            className="tab-pane fade show"
            id="pills-address"
            role="tabpanel"
            aria-labelledby="pills-address-tab"
          >
            <div className="dashboard-address">
              <div className="title title-flex">
                <div>
                  <h2>My Address Book</h2>
                  <span className="title-leaf">
                    <svg className="icon-width bg-gray">
                      <use xlinkHref="https://themes.pixelstrap.com/fastkart/assets/svg/leaf.svg#leaf" />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="row g-sm-4 g-3">
                {deleteClientDetails.Addresses &&
                  deleteClientDetails.Addresses.map((data, index) => (
                    <div
                      className="col-xxl-4 col-xl-6 col-lg-12 col-md-6 p-1"
                      key={index}
                    >
                      <div className="address-box ">
                        <div>
                          <div className="label">
                            <label>{data.Type}</label>
                          </div>
                          <div className="table-responsive address-table">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <td colSpan={2}></td>
                                </tr>
                                <tr>
                                  <td>Address :</td>
                                  <td>
                                    <p>{data.HNo + "," + data.StreetDet}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Pin Code :</td>
                                  <td>{data.Pincode}</td>
                                </tr>
                                <tr>
                                  <td>Phone :</td>
                                  <td>+91- {data.Number}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="bottompadd"></div>
        </>
      ) : (
        <></>
      )}
      <div className="mt-lg-3">
        <button
          className="btn theme-bg-color text-white btn-sm fw-bold mt-lg-0 mt-3"
          onClick={() => handleDeleteAccount()}
        >
          <i data-feather="plus" className="me-2" /> Delete Your Account
        </button>
      </div>
    </>
  );
};

export default MyAddress;
