import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  subCategoryTotal: localStorage.getItem("subCategoryTotal")
    ? JSON.parse(localStorage.getItem("subCategoryTotal"))
    : [],

  isSubCategoryAvailable: localStorage.getItem("subCategoryTotal")
    ? true
    : false,
  isSubCategoryLoading: true,
};

export const getSubCategory = createAsyncThunk(
  "subCategory/getSubCategory",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/subcategory/all`;
      const resp = await axios.get(url);
      return resp.data.subcategory;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

const SubCategorySlice = createSlice({
  name: "SubCategory",
  initialState,
  extraReducers: {
    [getSubCategory.pending]: (state) => {
      state.isSubCategoryLoading = true;
    },
    [getSubCategory.fulfilled]: (state, action) => {
      if (action.payload) {
        state.subCategoryTotal = action.payload.filter(
          (subcat) => subcat.status === true
        );
        localStorage.setItem(
          "subCategoryTotal",
          JSON.stringify(state.subCategoryTotal)
        );

        state.isSubCategoryLoading = false;
        state.isSubCategoryAvailable = true;
      }
    },
    [getSubCategory.rejected]: (state, action) => {
      state.isSubCategoryLoading = true;
    },
  },
});

export default SubCategorySlice.reducer;
